.button label {
  /* same color as border-color of text inputs */
  --color: #ced4da;
  --color-darker: rgb(128, 128, 128);
  --color-shadow-rgb: 128, 128, 128;
}

.button .input:checked + label {
  --color: var(--bs-primary);
  /* same color as an active btn-primary */
  --color-darker: #0a58ca;
  --color-shadow-rgb: var(--bs-primary-rgb);
}

/* Set color of border and checkbox icon */

.button label {
  color: var(--color);
  border-color: var(--color);
}

.input:active + label,
.input:focus + label,
.button:hover .input + label {
  color: var(--color-darker);
  border-color: var(--color-darker);
}

.input:active + label,
.input:focus + label {
  /* default shadow for active btn */
  box-shadow: 0 0 0 0.25rem rgba(var(--color-shadow-rgb), 0.25);
}

.input {
  /* Same styles as .btn-check for hidden input */
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.engineIcon {
  /* Smaller radius than .rounded-1 for consistency with the checkbox icon */
  border-radius: 0.1rem;
}

.labelText {
  /* Color of label text should never change */
  color: black;
}
