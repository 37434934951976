:global(#root) {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
}

@media (max-width: 480px) {
  .main,
  .footer {
    padding-left: 0.65rem;
    padding-right: 0.65rem;
  }
}

@media (max-width: 992px) {
  /* Make container responsive for size md and lower */
  .main,
  .footer {
    max-width: 100%;
  }
}
