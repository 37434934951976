/** Global styles and overrides **/

html {
  font-size: 15px;
}

a {
  text-decoration: none;
}
a:hover,
a:active,
a:focus {
  text-decoration: underline;
}

.table {
  --bs-table-striped-bg: rgba(0, 0, 0, 0.02);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.05);
}

/** Utils **/

.verticalSeparator {
  color: #9c9c9c;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
