.engineIcon {
  margin-right: auto;
  padding-right: var(--cell-items-padding);
}

.torrentLink {
  flex: 1 1 auto;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word; /* non-standard but works in chrome */
}

.downloadLink {
  color: #214c71;
  padding-left: 0.75rem;

  /* Increase invisible click surface */
  padding-top: 0.5rem;
  margin-top: -0.5rem;
  padding-right: 0.5rem;
  margin-right: -0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: -0.5rem;
}

.cellInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;

  white-space: nowrap;

  --cell-items-padding: 0.5rem;
  margin-right: calc(-1 * var(--cell-items-padding));

  --cell-vertical-spacing: 0.25rem;
  margin-top: calc(-1 * var(--cell-vertical-spacing));
}
.cellInfo > * {
  padding-right: var(--cell-items-padding);
  margin-top: var(--cell-vertical-spacing);
}
